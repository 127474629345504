.photo {
  width: 200px;
  height: 130px;
  -webkit-transition: -webkit-transform 0.1s;
  transition: transform 0.8s;
  opacity: 0.6;
  margin: 0px 2px 20px 20px;
  object-fit: cover;
  border-radius: 5px;
}

.main-photo {
  width: 880px;
  height: 550px;
  margin-top: 17px;
  animation: fadein 1.5s;
  border-radius: 5px;
  object-fit: cover;
}

.photo-view {
  background-color: black;
}

.photo-bg {
  padding-bottom: 60px;
}

.photo:hover {
  opacity: 1;
  cursor: pointer;
  -webkit-transform: scale(1.1) rotate(0.01deg);
  transform: scale(1.1) rotate(0.01deg);
}

.photo-quote-container {
  text-align: right;
  margin: 0px 40px 100px 0px;
}

.photo-quote-container2 {
  margin: 0px 10px 100px 50px;
  animation: fadein 1s;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 5px;
}

.photo-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 40px;
  padding-left: 10px;
}

.change-photos {
  font-family: Inter;
  font-size: 20px;
  cursor: pointer;
  color: white;
  padding: 7px 30px;
  text-align: center;
  border: 1px solid rgb(37, 43, 44);
  border-radius: 20px;
  margin: 8px;
}

.change-photos:hover {
  color: white;
  border: 1px solid white;
}

.photo-nav {
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 50px;
  gap: 8px;
}

.large-photo-container {
  padding: 20px;
  width: 900px;
}

.img-description {
  color: white;
  text-align: center;
  margin-top: 30px;
  font-family: "Rubik", sans-serif;
}

.quote {
  color: white;
  text-align: center;
  font-size: 25px;
  padding: 40px;
  font-family: Inter;
}

.quote-break {
  margin-top: 10px;
}

.photo-subtitle {
  color: white;
  margin-left: 100px;
  font-family: Inter;
}

.photo-head-container {
  color: white;
  display: grid;
  grid-template-columns: 5fr 8fr;
}

.photo-header {
  font-size: 28px;
  color: white;
  padding-top: 40px;
  padding-right: 100px;
  margin-left: auto;
}

.arrow-icon {
  padding: 30px;
}

@media screen and (max-width: 600px) {
  .quote {
    font-size: 14px;
    margin: 0px;
  }
  .photo-subtitle {
    font-size: 6px;
    margin-left: 12px;
  }

  .large-photo-container {
    width: auto;
    padding: 18px;
  }

  .change-photos {
    padding: 6px;
    font-size: 10px;
    padding-left: 5px;
  }

  .photo-nav {
    margin: 0px;
    padding-top: 5px;
  }

  .span.change-photos {
    font-size: 5px;
  }
  .main-photo {
    width: 100%;
    height: 200px;
    margin-top: 0px;
    object-fit: cover;
  }

  .container {
    display: flex;
    flex-direction: column-reverse;
  }
  .photo-bg {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
  }
  .photo {
    width: 80%;
    height: 80%;
  }

  .img-description {
    display: none;
  }

  .photo-head {
    margin-left: 40px;
  }

  .photo-header {
    font-size: 22px;
    padding: 0px;
    display: flex;
    place-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .photo-head-container {
    padding-top: 20px;
  }

  .photo-container {
    padding: 0px;
  }

  .arrow-icon {
    padding: 14px;
  }
}

@media screen and (min-width: 900px) {
  .photo {
    width: 80%;
    height: 80%;
  }

  .photo-container {
    grid-gap: 10px;
  }
}
