@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");

@font-face {
  font-family: "Suisse";
  src: url("/public/fonts/Suisse.ttf");
}

@font-face {
  font-family: "PolySans";
  src: url("/public/fonts/PolySans.woff");
}

::selection {
  color: white;
  background: rgb(27, 26, 26);
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: PolySans;
}

/* burger */
.MuiDrawer-content {
  background-color: black !important;
  width: 100% !important;
}

.MuiListItemButton-root {
  background-color: black !important;
}

.MuiIconButton-root:hover {
  border: none;
  background-color: black !important;
}

.MuiModalClose-root:hover {
  border: none;
  background-color: black !important;
}

.MuiListItemButton-colorNeutral {
  background-color: black;
}

.burger-link {
  text-decoration: none;
  color: rgb(45, 255, 167);
}

.hero-stars {
  top: -1px;
  width: 100%;
  max-width: 1400px;
  position: absolute;
  z-index: -1;
}

.hero-img {
  width: 100%;
}

/* Ensure elements above the SVG are positioned and have a higher z-index */

.hi-container {
  display: flex;
  flex-direction: column;
  place-items: center;
  height: 63%;
  justify-content: center;
  position: relative;
  z-index: 1; /* Higher than the SVG */
}

.bg {
  transition: background-color 1s ease-in-out;
}

.main-page-container {
  padding-bottom: 100px;
  position: relative;
  z-index: 1; /* Higher than the SVG */
}

.hero-img {
  position: absolute;
  top: -1;
}

.hi {
  padding-top: 240px;
  font-size: 100px;
  color: rgb(45, 255, 167);
  text-shadow: 0 0 6px rgb(45, 255, 167);
}

.hi-lower {
  font-size: 40px;
  color: rgb(198, 198, 198);
  text-shadow: 0 0 6px #cccfcabf;
}

.contents-container {
  z-index: 0;
  display: flex;
  grid-template-columns: 1fr 1fr;
}

.asset-container {
  display: flex;
  justify-content: center;
}

.nav-bar {
  display: flex;
  justify-content: space-around;
  transition: background-color 1s ease-in-out;
  font-size: 35px;
  color: rgb(198, 198, 198);
  padding: 50px;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  text-shadow: 0 0 6px #cccfcabf;
  z-index: 1;
}

.links {
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
}

.photo-link {
  text-decoration: none;
  color: rgb(198, 198, 198);
  font-size: 14px;
  padding: 0px 5px;
  margin-top: auto;
}

.nav-icons {
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  z-index: 1;
  padding-right: 20px;
}

.github-icon {
  padding-left: 10px;
}

.footer {
  border-top: 1px solid rgba(194, 193, 193, 0.477);
  margin: 0px 35px;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

.name {
  padding: 10px;
  color: rgb(45, 255, 167);
  font-size: 14px;
}

/* text */

.line {
  display: flex;
  justify-content: space-between;
}

#home-text {
  padding: 0px 80px 0px 90px;
  margin: 300px 0px;
}

.word {
  font-size: 55px;
  font-family: "Rubik", sans-serif;
  margin: 0.5rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.817);
  background-color: rgb(4, 4, 4);
}

.about {
  font-family: Inter;
  font-size: 16px;
  color: white;
  text-align: center;
}

.about-right {
  padding: 30px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 10px;
  background-color: rgba(64, 63, 63, 0.033);
}

.about-center {
  padding: 20px;
  background-color: rgba(64, 63, 63, 0.033);
  border-radius: 10px;
}

.about-left {
  display: flex;
  flex-direction: column;
  padding: 40px 20px 20px 20px;
  background-color: rgba(64, 63, 63, 0.033);
  border-radius: 10px;
}

.about > div > p {
  padding: 2rem;
  border-radius: 10px;
}

.projects-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px;
  gap: 2rem;
}

.project-box {
  display: flex;
  justify-content: center;
  padding: 50px 60px;
  border: 1px solid rgba(194, 193, 193, 0.477);
  border-radius: 10px;
  flex-direction: column;
  place-items: center;
  color: white;
  font-family: Inter;
  background-color: rgba(64, 63, 63, 0.033);
  cursor: pointer;
}

.project-box:hover {
  background-color: rgba(61, 60, 60, 0.1);
}

/* .welcome-container {
  position: relative;
  z-index: 2;
  overflow: hidden;
} */

.welcome {
  width: 100%;
  box-sizing: border-box;
  padding: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.skillset {
  color: rgb(198, 198, 198);
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.my-stack {
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 100px;
  background-color: rgba(64, 63, 63, 0.033);
  border-radius: 10px;
  padding: 30px;
}

.home-techs {
  color: rgba(45, 255, 167, 0.764);
  border: 1px solid rgb(45, 255, 167);
  background-color: rgba(64, 63, 63, 0.033);
  padding: 4px;
  border-radius: 2px;
  font-size: 13px;
  gap: 12px;
  margin: 2px;
}

.home-technologies {
  font-size: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 60px;
}

.stack-header {
  font-size: 80px;
  text-align: center;
  padding-top: 60px;
  color: rgb(198, 198, 198);
  text-shadow: 0 0 6px #cccfcabf;
}

.icons {
  display: flex;
  flex-direction: row;
  padding: 40px;
  justify-content: space-around;
  z-index: 3;
}

.accordion-container {
  position: relative;
  z-index: 2;
  background-color: rgb(12, 9, 16);
  color: white;
  padding: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh; /* max height to viewport height */
  overflow-y: auto; /* enable vertical scrolling */
}

.latest-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  display: flex;
  padding-right: 50px;
  text-align: center;
  margin-bottom: 100px;
}

.latest-work {
  font-size: 30px;
  margin-bottom: 80px;
  text-align: right;
  font-family: "Polysans";
  padding-top: 20px;
  border-radius: 5px;
  color: rgb(45, 255, 167);
  text-shadow: 0 0 6px rgb(45, 255, 167);
}

.latest-date {
  color: rgb(45, 255, 167);
  text-shadow: 0 0 6px rgb(45, 255, 167);
  text-align: right;
  font-size: 20px;
}

/* text

/* swiper */

.image-slider {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-img {
  width: 100%;
  margin: 20px 0px;
  padding: 8px;
  object-fit: contain;
  position: relative;
  background-color: rgba(207, 207, 207, 0.128);
  border-radius: 5px;
  box-shadow: rgba(255, 255, 255, 0.3) 0px 1px 2px 0px, rgba(255, 255, 255, 0.15) 0px 2px 6px 2px;
  background-image: url("/public/images/gradient.webp");
  background-size: contain;
  background-repeat: no-repeat;
}

.swiper-buttons {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  padding: 30px;
  right: 8%;
  height: 100px;
  width: 78%;
  color: rgb(255, 255, 255);
}

.swiper-buttons:hover {
  text-shadow: 0 0 8px #cccfcabf;
}

.back-arrow,
.forward-arrow {
  cursor: pointer;
}

.back-arrow {
  position: absolute;
  left: 0%;
}

.forward-arrow {
  position: absolute;
  right: 0%;
}

/* .arrow-icons {

} */

.accordion-summary {
  border-radius: 10px;
}

.Mui-expanded {
  margin: 0px;
}

/* animation */
.hover-underline-animation {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  margin-top: 6px;
  background-color: rgb(198, 198, 198);
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
/* animation */

/* /swiper */

.project {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.projects-header {
  color: rgb(198, 198, 198);
  display: flex;
  justify-content: center;
}

.projects-container {
  margin-top: 40px;
}

.about-project {
  font-size: 40px;
  font-family: "Rubik", sans-serif;
}

.project-text {
  padding-top: 20px;
  font-family: Inter;
  color: white;
}

.project-techs {
  color: rgb(45, 255, 167);
  border: 1px solid rgb(45, 255, 167);
  padding: 4px;
  border-radius: 1px;
  margin-right: 4px;
  font-size: 8px;
}

.technologies {
  padding-top: 10px;
  font-size: 12px;
}

.project-link {
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-decoration: none;
  color: white;
  padding-top: 10px;
}

.link:hover {
  color: rgb(45, 255, 167);
}

.project-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}

.project-description {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
  border-radius: 5px;
  width: 50%;
  text-shadow: 0 0 8px #cccfcabf;
}

/* contact form */

textarea {
  width: 50%;
  height: 190px;
  resize: none;
  outline: none;
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  margin-top: 40px;
  font-family: Inter;
  padding: 10px;
  font-size: 14px;
  border: 1px solid rgba(194, 193, 193, 0.477);
  background-size: cover;
  background-color: rgba(4, 4, 4, 0.1);
}

.contact-text {
  display: flex;
  justify-content: center;
}

input {
  width: 15rem;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  color: white;
  border: 1px solid rgba(194, 193, 193, 0.477);
  background-size: cover;
  background-color: rgba(4, 4, 4, 0.1);
  font-family: Inter;
  font-size: 11px;
}

.inputs {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
  gap: 20px;
  place-items: center;
}

::placeholder {
  color: white;
}

.button-container {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.send-button {
  height: fit-content;
  width: 5rem;
  font-family: Inter;
  font-size: 12px;
  cursor: pointer;
  color: white;
  padding: 10px;
  text-align: center;
  border: 1px solid rgb(37, 43, 44);
  border-radius: 20px;
  margin-top: 35px;
  background-color: rgba(4, 4, 4, 0.1);
}

.send-button:hover {
  color: white;
  border: 1px solid white;
}

.contact {
  text-align: center;
}

.contact-container {
  z-index: 3;
  overflow: hidden;
  position: sticky;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contact-message {
  color: rgb(198, 198, 198);
}

.top-button {
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  margin: 37px 12px;
  border: 1px solid rgb(37, 43, 44);
}

.top-button:hover {
  color: white;
  border: 1px solid white;
}

.return-top {
  display: flex;
  justify-content: center;
}

.return-top-homescreen {
  background-color: rgba(64, 56, 243, 0.73);
  padding-right: 70px;
}

@media screen and (max-width: 601px) {
  .MuiAccordionDetails-root {
    padding: 0px 20px 0px 0px !important;
  }
  .hero-img {
    width: 100%;
  }

  .hero-stars {
    width: 100%;
  }

  .main-page-container {
    display: flex;
    flex-direction: column;
  }

  .welcome {
    display: flex;
    flex-direction: column;
  }

  .about-left {
    padding: 20px;
    margin: 20px;
    text-align: center;
  }

  .about-right {
    padding: 20px;
    margin: 28px 20px 20px 20px;
    text-align: center;
  }

  .about-center {
    padding: 20px;
    margin: 20px;
    text-align: center;
  }

  .contact-message > p {
    margin-top: 0;
    padding: 5px;
    font-size: 14px;
  }

  textarea {
    width: 50%;
  }

  .hi-container {
    padding: 100px 0 0 0;
    display: flex;
    place-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .hi {
    font-size: 60px;
    padding-bottom: 20px;
    padding-top: 0;
  }

  .hi-lower {
    font-size: 20px;
  }

  .asset-container {
    padding-top: 168px;
  }
  .word {
    font-size: 25px;
  }

  .about {
    font-size: 14px;
    gap: 20px;
  }

  .welcome {
    padding: 0px;
    gap: 0;
  }

  .nav-bar {
    font-size: 20px;
    display: flex;
    justify-content: space-between;
  }

  .stack-header {
    font-size: 40px;
  }

  .my-stack {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 25px;
    padding: 50px;
  }

  .mongodb-icon {
    width: 80%;
    padding-right: 20px;
  }

  .latest-work {
    margin-bottom: 20px;
    padding-bottom: 30px;
    font-size: 16px;
  }

  .latest-container {
    padding: 20px;
  }

  .back-arrow {
    margin-top: 125px;
    left: 10%;
  }

  .forward-arrow {
    margin-top: 125px;
  }

  .project {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  -header {
    font-size: 2em;
  }

  .latest-date {
    padding-right: 10px;
    font-size: 12px;
  }

  .about-project {
    font-size: 20px;
  }

  .accordion-container {
    padding: 30px 10px 100px 10px;
  }

  .project-description {
    margin-top: 10px;
    margin-bottom: 12px;
    width: 70%;
    border-radius: 5px;
    padding: 10px;
  }

  .project-link {
    margin-bottom: 20px;
  }

  .project-text {
    font-size: 12px;
  }
  .technologies {
    font-size: 12px;
  }

  .contact-container {
    display: block;
    padding-bottom: 20px;
  }

  .return-top-homescreen {
    padding-right: 54px;
  }

  textarea {
    width: 82%;
    margin-top: 0;
    height: 120px;
  }

  age {
    font-size: 20px;
    padding: 22px;
    text-align: center;
  }

  .contact-message {
    padding: 3rem;
    margin-top: 60px;
  }

  .contact-container {
    padding-top: 2px;
  }

  .return-top {
    padding-right: 25px;
  }

  .button-container {
    padding-right: 0px;
    justify-content: center;
    gap: 20px;
  }

  .inputs {
    place-items: center;
    padding-right: 0px;
  }

  .email {
    font-size: 18px;
    padding: 0px 20px;
  }

  .photo-link {
    padding: 6px;
  }

  .hover-underline-animation {
    padding-top: 8px;
  }

  .top-button {
    font-size: 18px;
    padding: 9px;
    margin: 6px 0px;
    display: flex;
    justify-content: flex-end;
  }

  button {
    margin-top: 8px;
  }

  .send-button {
    margin-top: 9px;
    font-size: 10px;
  }

  .links {
    gap: 10px;
    padding-right: 20px;
  }

  .projects-grid {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .project-box {
    gap: 7px;
    margin-top: 35px;
  }

  .name {
    font-size: 12px;
  }

  .nav-icons {
    padding: 0;
  }

  .hover-underline-animation {
    padding-top: 5px;
  }

  .skillset {
    padding: 20px;
    font-size: 16px;
  }
  .home-technologies {
    padding: 2px;
  }
  .project-header {
    margin-bottom: 35px;
  }
}
