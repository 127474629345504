.floating-nav {
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  padding: 9px 8px;
  background-color: rgba(114, 114, 114, 0.2);
  backdrop-filter: blur(20px);
  /* border: 1px solid #404040b5; */
  box-shadow: 0 2px 10px #0000001a;
  align-items: center;
  border-radius: 30px;
  transition: all 0.4s ease;
  width: 30%;
  height: 40px;
}

.floating-nav-container {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.fn-links {
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 17px;
  z-index: 2;
  margin: 0 5px;
  border-radius: 30px;
  color: rgb(198, 198, 198);
}

.fn-links:hover {
  color: white;
}
